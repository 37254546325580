

import request from "./request"
import { cancelRequest } from '@/utils/utils';
import axios from 'axios'
import store from "@/store";
export function getUserProfile(query) {
  return request({
    url: '/api/website/chatRecords-admin/total',
    method: 'get',
    params: query
  })
}
// 这个接口传入授权code，后台更新用户信息并返回openid
export function getVisitorInfoByCode(query) {
  return request({
    url: '/api/website/users/Client',
    method: 'get',
    params: query
  })
}
// 这个接口传入授权code，后台更新推广员信息并返回openid
export function getPromopterInfoByCode(query) {
  return request({
    url: '/api/website/users/Avatar',
    method: 'get',
    params: query
  })
}
export function getLinkStatus(id) {
  return request({
    url: '/api/website/userHistorys/' + id,
    method: 'get'

  })
}
export function getWxJsdk(query) {
  const source = axios.CancelToken.source();
  cancelRequest('getWxJsdk');
  window.$httpRequestList.set('getWxJsdk', source)
  return request({
    url: '/api/TokenAuth/WeChatJsdk',
    method: 'get',
    params: query,
    cancelToken: source.token
  })
}
export function getMessageCode(query) {
  return request({
    url: '/api/identity/users/getlogincode',
    method: 'get',
    params: query
  })
}
// 通用验证码接口
export function getCommonMessageCode(query) {
  return request({
    url: '/api/identity/users/getverifycode',
    method: 'get',
    params: query
  })
}
export function loginByMessageCode(form) {
  return request({
    url: '/api/TokenAuth/PhoneCodeLogin',
    method: 'post',
    data: form
  })
}
export function loginByWxAuth(form) {
  return request({
    url: '/api/TokenAuth/WeixinLogin',
    method: 'post',
    data: form
  })
}
export function Register(form) {
  return request({
    url: '/api/identity/users/Register',
    method: 'post',
    data: form
  })
}
export function feedBack(form) {

  return request({
    url: '/api/website/feedback',
    method: 'post',
    data: form
  })
}
export function postUserHistory(form) {
  return request({
    url: '/api/website/userHistorys-admin',
    method: 'post',
    data: form
  })
}
export function getUserInfo() {
  return request({
    url: '/api/identity/users/getmy',
    method: 'get'

  })
}

export function getUserByShortCode(query) {
  return request({
    url: '/api/website/users/user',
    method: 'get',
    params: query
  })
}



// 上传图片
export function uploadImage(data, onUploadProgress) {
  return request({
    url: '/api/business/upload/multiple',
    method: 'post',
    onUploadProgress,
    timeout: 0,
    data
  })
}
// 上传微信二维码
export function uploadQrcode(data) {
  return request({
    url: '/api/identity/users/change-wechatqrcode',
    method: 'put',
    data
  })
}
// 验证二维码
export function verifySmsCode(data) {
  return request({
    url: '/api/identity/users/verifyverifycode',
    method: 'put',
    data
  })
}
// 验证二维码 注册专用
export function verifyRegistSmsCode(data) {
  return request({
    url: '/api/identity/users/regverifyverifycode',
    method: 'put',
    data
  })
}

export function getDepartment() {
  return request({
    url: 'api/identity/organization-units',
    method: 'get'

  })
}
export function getTelCode(query) {
  return request({
    url: '/api/identity/users/getcode',
    method: 'get',
    params: query
  })
}

export function putUserOrganization(userId, data) {
  return request({
    url: `api/identity/users/${userId}/organization-units`,
    method: 'put',
    data

  })
}
export function putUserName(data) {
  return request({
    url: `/api/identity/users/change-myname`,
    method: 'put',
    data

  })
}
export function withdrawHis(id, data) {
  return request({
    url: `/api/website/userHistorys-admin/${id}`,
    method: 'put',
    data

  })
}
export function putUserQQ(data) {
  return request({
    url: `/api/identity/users/change-qq`,
    method: 'put',
    data

  })
}
export function putUserPassword(data) {
  return request({
    url: `/api/identity/users/change-mypassword`,
    method: 'put',
    data

  })
}
export function putUserPhone(data) {
  return request({
    url: `/api/identity/users/change-phone`,
    method: 'put',
    data

  })
}
export function postImg(data) {
  return request({
    url: `/api/business/upload/multiple`,
    method: 'put',
    data
  })
}

export function getDialogConfig() {
  return request({
    url: '/api/website/chatRecords-admin/Popularize',
    method: 'get'

  })
}

export function putDialogConfig(data) {
  return request({
    url: `/api/website/chatRecords-admin/Popularize`,
    method: 'put',
    data

  })
}
export function getNotifyConfig() {
  return request({
    url: '/api/website/chatRecords-admin/Notice',
    method: 'get'

  })
}

export function putNotifyConfig(data) {
  return request({
    url: `/api/website/chatRecords-admin/Notice`,
    method: 'put',
    data

  })
}
export function prompterLike(id, data) {
  return request({
    url: `/api/website/users/${id}/update-liker`,
    method: 'put',
    data
  })
}

export function prompterCancelLike(id, data) {
  return request({
    url: `/api/website/users/${id}/update-canceliker`,
    method: 'put',
    data
  })
}


export function getStyles() {
  return request({
    url: `https://api2122.yzf.com.cn/api/website/common-lookup/styles`,
    method: 'get',
    headers: { //头部参数
      yzftenant: store.state.user.promopterInfo.tenantName
    }
  })
}

export function getHouseTypes() {
  return request({
    url: `https://api2122.yzf.com.cn/api/website/common-lookup/house-types`,
    method: 'get',
    headers: { //头部参数
      yzftenant: store.state.user.promopterInfo.tenantName
    }
  })
}

export function getCluePhone(_id) {
  return request({
    url: `/api/website/clues-admin/${_id}/phone`,
    method: 'get'
  })
}
export function getTenants() {
  return request({
    url: `/api/app/common-lookup/tenants`,
    method: 'get'
  })
}
export function uploadWxQrcode(data) {
  return request({
    url: `/api/business/upload/multiple`,
    method: 'post',
    data,
   
  })
}
export function uploadRegist(data,tenantName) {
  return request({
    url: `/api/business/upload`,
    method: 'post',
    data,
    headers: { //头部参数
      yzftenant: tenantName
    }
   
  })
}

