const state = {
    oldUserInfoForRegist: null,
    newUserInfoForRegist: null,
}

const mutations = {
    SET_OLD_USER_INFO_FOR_REGIST(state, value) {
        state.oldUserInfoForRegist = value
    },
    SET_NEW_USER_INFO_FOR_REGIST(state, value) {
        state.newUserInfoForRegist = value
    }
}

const actions = {
    setOldUserInfoForRegist({ commit }, value) {
        commit('SET_OLD_USER_INFO_FOR_REGIST', value)
    },
    setNewUserInfoForRegist({ commit }, value) {
        commit('SET_NEW_USER_INFO_FOR_REGIST', value)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
        
